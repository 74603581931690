import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Alert from "../components/Alert";
import "./keep.css";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { Script, withPrefix } from "gatsby";
import { ParallaxProvider } from "react-scroll-parallax";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <ParallaxProvider>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />

          <link
            rel="mask-icon"
            href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
            color="#339285"
          />
          {/* <!-- ****** faviconit.com favicons ****** --> */}
          <link rel="shortcut icon" href="/img/favicon/favicon.ico" />
          <link
            rel="icon"
            sizes="16x16 32x32 64x64"
            href="/img/favicon/favicon.ico"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="196x196"
            href="/img/favicon/favicon-192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="160x160"
            href="/img/favicon/favicon-160.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/img/favicon/favicon-96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="64x64"
            href="/img/favicon/favicon-64.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/img/favicon/favicon-32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/img/favicon/favicon-16.png"
          />
          <link rel="apple-touch-icon" href="/img/favicon/favicon-57.png" />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/img/favicon/favicon-114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/img/favicon/favicon-72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/img/favicon/favicon-144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/img/favicon/favicon-60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/img/favicon/favicon-120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/img/favicon/favicon-76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/img/favicon/favicon-152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/favicon/favicon-180.png"
          />
          <meta name="msapplication-TileColor" content="#FFFFFF" />
          <meta
            name="msapplication-TileImage"
            content="/img/favicon/favicon-144.png"
          />
          <meta
            name="msapplication-config"
            content="/img/favicon/browserconfig.xml"
          />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="https://evolvestrongfitness.com" />
          <meta
            property="og:image"
            content={`${withPrefix("/")}img/og-image.jpg`}
          />
          <link rel="preconnect" href="//privacy-proxy.usercentrics.eu" />
          <link rel="preload" href="//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" as="script" />
          <Script id="usercentrics-block" type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></Script>
          <Script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="23mD7dV_C7oJi5" async></Script>
          <Script>{`uc.setCustomTranslations('https://termageddon.ams3.cdn.digitaloceanspaces.com/translations/');`}</Script>
        </Helmet>
        <Alert />
        <Navbar />
        <div>{children}</div>
        <div
          className="position-relative has-background-white"
          dangerouslySetInnerHTML={{
            __html: `<script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="https://lightwidget.com/widgets/5799de8f0871554a910450d1a498d20e.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;height: calc(100vw / 5 * 2);"></iframe>`,
          }}
        />
        <Footer />
      </ParallaxProvider>
    </div>
  );
};

export default TemplateWrapper;
