import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "./alert.css"

const Alert = () => {
  const { site } = useStaticQuery(query);

  const { alertText, alertLink } = site.siteMetadata;

  return (
    <div>
      {alertText ? (
        <div className="alert-wrapper">
          {alertLink ? (
            <a
              href={alertLink ? alertLink : `#`}
              rel="noreferrer"
              target="_blank"
              className="alert-link"
            >
              <span className="alert">{alertText}</span>
            </a>
          ) : (
            <span className="alert">{alertText}</span>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Alert;

const query = graphql`
  query Alert {
    site {
      siteMetadata {
        alertText
        alertLink
      }
    }
  }
`;
