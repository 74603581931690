import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const Banner = ({ props }) => (
  <>
    {(props?.heading || props?.image || props?.video) ? <div>
      <div className="position-relative banner-container">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
          }}
        >
          <div className="banner h-100">
            {props.bannerType === "video" && props.video?.publicURL ? (
              <video
                controls={false}
                autoPlay
                muted
                loop
                playsInline
                onLoad="play()"
                onClick="play()"
                preload="auto"
                webkit-playsInline
                className="position-relative banner-video h-100"
                poster={props.image}
              >
                <source src={props.video.publicURL} type="video/mp4" />
              </video>
            ) : (
              <PreviewCompatibleImage
                imageInfo={{
                  image: props.image,
                  alt: props.imageAlt,
                  className: "banner-image w-100 h-100",
                }}
              />
            )}
            {props.image ? <PreviewCompatibleImage
              imageInfo={{
                image: props.image,
                alt: props.imageAlt,
                className: "banner-image banner-image-mobile w-100 h-100",
              }}
            /> : null}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            position: "absolute",
            background:
              "linear-gradient(rgba(0, 0, 0, .5) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.5) 100%)",
          }}
        ></div>
        <div className="container w-100 flex-grow-unset">
          {props?.heading ? (
            <h2
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="1000"
              className="banner-heading position-relative ml-3"
            >
              {props.heading}
            </h2>
          ) : null}
        </div>
      </div>
    </div> : <div style={{ height: "10rem", backgroundColor: "#333" }} />}
  </>
);

Banner.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageAlt: PropTypes.string,
  video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bannerType: PropTypes.string,
  heading: PropTypes.string,
};

export default Banner;
