import * as React from "react";

function ChevronDown(props) {
  const { stroke, ...restProps } = props;
  return (
    <svg
      width={14}
      height={8}
      fill="none"
      strokeWidth={3}
      stroke={stroke}
      strokeLinecap="square"
      className="chevron-down-svg"
      {...restProps}
    >
      <path d="M1.962 1.793l4.99 4.414 4.678-4.414" />
    </svg>
  );
}

// Set default props
ChevronDown.defaultProps = {
  stroke: "#FFFFFF",
};

export default ChevronDown;
